import img1 from '../images/post/post_1.jpg'
import img2 from '../images/post/post_2.jpg'
import img3 from '../images/post/post_3.jpg'
import img4 from '../images/post/post_4.jpg'
import img5 from '../images/post/post_5.jpg'
import img6 from '../images/post/post_6.jpg'
import img7 from '../images/post/post_7.jpg'
import img8 from '../images/post/post_8.jpg'
import img9 from '../images/post/post_9.jpg'
import img10 from '../images/post/post_10.png'
import img11 from '../images/post/post_11.png'
import img12 from '../images/post/post_12.png'
import img13 from '../images/post/post_13.png'
import img14 from '../images/post/post_14.png'
import img15 from '../images/post/post_15.png'
import img16 from '../images/post/post_16.png'
import img17 from '../images/post/post_17.png'
import img18 from '../images/post/post_18.png'
import img19 from '../images/post/post_19.png'

const dataBlog = [
  {
    id: 1,
    img: img1,
    heading: 'Decoded: Metaverse and the future of banking',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 2,
    img: img2,
    heading: 'Tapping the Metaverse for immersive service',
    cate: 'Web 3.0',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 3,
    img: img3,
    heading: 'JetSynthesys to invest $50 million to build metaverse...',
    cate: 'Crypto Trade',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 4,
    img: img4,
    heading: 'Creating a constitutional framework for metaverse',
    cate: 'Farming',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 5,
    img: img5,
    heading: 'Disney CEO lays out early plan for digital future',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 6,
    img: img6,
    heading: 'Shemaroo to partner with Seracle for Web3 foray',
    cate: 'Web 3.0',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 7,
    img: img7,
    heading: 'New VR jobs die down at Meta, Google amid hiring freeze...',
    cate: 'Crypto Trade',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 8,
    img: img8,
    heading: 'Digital India Act to police social media and OTT platforms',
    cate: 'Farming',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 9,
    img: img9,
    heading: 'Meta raises $10 billion in first-ever bond offering',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },

  {
    id: 10,
    img: img10,
    heading: 'Now Shemaroo offers movie theatre on metaverse',
    cate: 'IGOs',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 11,
    img: img11,
    heading:
      'Skepticism, confusion, frustration: Inside Mark Zuckerbergs metaverse struggles',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 12,
    img: img12,
    heading:
      'Tencent shifts focus to majority deals, overseas gaming assets for growth: sources',
    cate: 'Crypto Trade',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 13,
    img: img13,
    heading: 'Meta working to speed up metaverse, but success far from certain',
    cate: 'Web 3.0',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 14,
    img: img14,
    heading: 'Microsoft brings animated avatars to Teams in metaverse push',
    cate: 'Crypto Trade',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 15,
    img: img15,
    heading: 'Tapping the Metaverse for immersive service',
    cate: 'Web 3.0',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 16,
    img: img16,
    heading:
      'Translucia forays into India market, to build $ 3 billion worth metaverses through...',
    cate: 'Farming',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 17,
    img: img17,
    heading: 'People still dont know what metaverse is all about: Tim Cook',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 18,
    img: img18,
    heading:
      'Tencent shifts focus to majority deals, overseas gaming assets for growth: sources',
    cate: 'Metaverse',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
  {
    id: 19,
    img: img19,
    heading: 'Together Labs subsidiary announces close of VCORE presale',
    cate: 'Farming',
    text: 'The metaverse can help the healthcare fraternity by providing trainings on understanding medical tools and...',
  },
]

export default dataBlog
