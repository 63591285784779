import React from 'react'

import Hero from '../components/hero'
import CTA from '../components/cta'

import icon from '../assets/images/common/down.svg'

import { useTranslation } from 'react-i18next'

function Trails(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      {
        <Hero
          title='Trails'
          desc='Unleash Your Potential And Master Your Skills'
          sub='Learning by playing on a platform with its own currency and engaging gamification process'
          i18n={t}
        />
      }

      <section className='tf-section roadmap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='roadmap-wrapper-style2'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                <div className='left'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Business + Entrepreneurship')}
                      </h6>
                      <p>
                        {t(
                          'Entrepreneurs are made, not born. This course was developed and designed in coordination with business school educational developers, industry consultants, and students.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Digital + Storytelling')}</h6>
                      <p>
                        {t(
                          'Students will begin by learning and sharing key thematic styles used by social content creators across multiple platforms. Students will learn the methods used by creators to publish different types of video and photo content on the social media platforms YouTube, TikTok, and Instagram.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Game Design')}</h6>
                      <p>
                        {t(
                          'This course offers a great introduction to game design principles, including interactive storytelling, character creation, and publishing. Our hands-on, design-thinking approach encourages students to unleash their inner designer as well as work collaboratively with other students.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Music Production')}</h6>
                      <p>
                        {t(
                          'Students learn the science of sound and synthesis and how to loop musical ideas in the Ableton Live session view. Students will arrange and produce an existing popular song and learn the fundamentals of music production.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='right'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Robotics + Engineering')}</h6>
                      <p>
                        {t(
                          'Few things are more rewarding than building something for yourself. Throughout the course, students will learn how to build and program robots using Arduino.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Computer Science + Artificial Intelligence')}
                      </h6>
                      <p>
                        {t(
                          'Students will create their own artificial intelligence, such as chatbots, natural language processing, and recommendation systems. Use AI tools like machine learning to create intelligent programs like chatbots from scratch.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Creative Design')}</h6>
                      <p>
                        {t(
                          'Discover how animators at Pixar, Cartoon Network, and Disney produce cartoons and films by understanding the principles of life drawing. Develop the art of storytelling by thinking sequentially to create storyboards.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA
        title='Make a register!'
        subtitle='Enroll your child now and be part of this transformation for the future.'
        button_title='Make a register!'
      />
    </div>
  )
}

export default Trails
