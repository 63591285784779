import React from 'react'

import img from '../../assets/images/backgroup/espaco.jpg'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'

function CTA(props) {
  const [dataTitle] = useState({
    title: props.title,
    subtitle: props.subtitle,
    button_title: props.button_title,
  })

  const { t } = useTranslation()
  return (
    <section className='tf-section tf_CTA'>
      <div className='container relative'>
        <div className='overlay'></div>
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='tf-title left mt58'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{t(dataTitle.title)}</h2>
              <p className='sub'>{t(dataTitle.subtitle)}</p>
              <div className='wrap-btn btn-desk'>
                            <Button
                              title={t('Make a register!')}
                              onClick={props.button_url}
                              addclass='style2'
                            />
                          </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='image_cta'
              data-aos='fade-left'
              data-aos-duration='1200'
            >
              <img className='move4 img_cta' src={img} alt='' />
            </div>
            <br />
            <br />
            <div className='wrap-btn btn-mobile'>
            <div className='wrap-btn btn-desk'>
                            <Button
                              title={t('Make a register!')}
                              onClick={props.button_url}
                              addclass='style2'
                            />
                          </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
