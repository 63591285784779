import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './styles.scss'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

function CarouselSlider(props) {
  const { data } = props
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={props.deviceType !== 'mobile' ? true : false}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      customTransition='all .5'
      transitionDuration={2000}
      containerClass='carousel-container'
      removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={props.deviceType}
      dotListClass='custom-dot-list-style'
      itemClass='carousel-item-padding-40-px'
    >
      {data.slice(props.sliceNumber1, props.sliceNumber2).map(item => (
        <div key={item} className='img-carousel'>
          <img src={item.img} alt='Edufuturo' />
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselSlider
