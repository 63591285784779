import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import './style.scss'

function Accordions(props) {
  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey={props.key}>
        <Accordion.Header>{props.title}</Accordion.Header>
        <Accordion.Body>{props.content}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default Accordions
