import React from 'react'
import PropTypes from 'prop-types'

import { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../styles.scss'

Project.propTypes = {
  data: PropTypes.array,
}

function Project(props) {
  const { data } = props

  return (
    <section className='tf-section project'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='tf-title'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>
                {props.i18n(props.title)}
                <br className='show-destop' /> {props.i18n(props.title_2)}
              </h2>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='project-wrapper'>
              <div
                className='image-wrapper'
                data-aos='fade-in'
                data-aos-duration='1000'
              >
                <div className='slider-1'>
                  <Swiper
                    modules={[Pagination, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                  >
                    {data.slice(0, 6).map(item => (
                      <SwiperSlide key={item.id} item={item}>
                        <img src={item.img} alt='Edufuturo' />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className='content-wrapper'>
                <div
                  className='content_inner'
                  data-aos='fade-left'
                  data-aos-duration='1200'
                >
                  <div className='wrapper'>
                    <h4>{props.i18n('The End Of Complexity')}</h4>
                    <p className='desc'>{props.i18n(props.desc)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Project
