import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

InMedia.propTypes = {
  data: PropTypes.array,
}

function InMedia(props) {
  const { data } = props

  const { t } = useTranslation()

  const [dataTitle] = useState({
    title: 'Us In The Media',
  })
  return (
    <section className='tf-section tf_partner'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='tf-title'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{t(dataTitle.title)}</h2>
            </div>
          </div>
          <div className='col-md-12'>
            <div
              className='partner-wrapper'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              {data.map(idx => (
                <img src={idx.img} alt='' key={idx.img} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InMedia
