import img1 from '../images/common/icon_1.png'
import img2 from '../images/common/icon_2.png'
import img3 from '../images/common/icon_3.png'
import img4 from '../images/common/icon_1.png'
import img5 from '../images/common/icon_4.png'
import img6 from '../images/common/icon_5.png'
import img7 from '../images/common/icon_6.png'
import img8 from '../images/common/icon_7.png'
import img9 from '../images/common/icon_8.png'
import img10 from '../images/common/icon_8.png'

const dataFeatured = [
  {
    id: 1,
    img: img1,
    title: 'Business + Entrepreneurship',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
  {
    id: 2,
    img: img2,
    title: 'High Feasibility',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
  {
    id: 3,
    img: img3,
    title: 'Easy Withdrawal',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
  {
    id: 4,
    img: img4,
    title: '+100 Cursos',
    text: 'Cursos em 5 áreas de conhecimento diferentes e em 2 idiomas',
  },

  {
    id: 5,
    img: img5,
    title: '+100 Cursos',
    text: 'Cursos em 5 áreas de conhecimento diferentes e em 2 idiomas',
  },

  {
    id: 6,
    img: img6,
    title: 'Completo',
    text: 'Estrutura de aprendizagem cognitiva completa',
  },
  {
    id: 7,
    img: img7,
    title: 'Ensino personalizado',
    text: 'Ensino personalizado e alunos protagonistas de suas jornadas',
  },
  {
    id: 8,
    img: img8,
    title: 'Core Skills',
    text: 'Desenvolvimento das Core Skills, um mix de soft skills e Hard Skills',
  },
  {
    id: 9,
    img: img9,
    title: 'Edufuturo Play',
    text: 'Plataforma própria de streaming e ensino gamificado',
  },
  {
    id: 10,
    img: img10,
    title: 'Metodologia exclusiva',
    text: 'Metodologia exclusiva com renomados nomes do mercado',
  },
  {
    id: 11,
    img: img8,
    title: 'Crianças e adolescentes',
    text: 'Crianças e adolescentes entre 7 e 17 anos.',
  },
  {
    id: 12,
    img: img9,
    title: 'Futuro',
    text: 'Pais que querem o melhor para os filhos',
  },
  {
    id: 13,
    img: img10,
    title: 'Liderança',
    text: 'Para todos aqueles que querem de verdade LIDERAR o futuro!',
  },
]

export default dataFeatured
