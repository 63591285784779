import img1 from '../images/space/01.jpg'
import img2 from '../images/space/02.jpg'
import img3 from '../images/space/03.jpg'
import img4 from '../images/space/04.jpg'
import img5 from '../images/space/05.jpg'
import img6 from '../images/space/06.jpg'

import img7 from '../images/common/project_5.png'
import img8 from '../images/common/project_6.png'
import img9 from '../images/common/project_7.png'
import img10 from '../images/common/project_21.png'
import img11 from '../images/common/project_1.png'
import img12 from '../images/common/project_22.jpg'
import img13 from '../images/common/project_23.jpg'
import img14 from '../images/common/project_37.png'
import img15 from '../images/common/project_38.png'
import img16 from '../images/common/project_39.png'
import img17 from '../images/common/project_40.png'
import img18 from '../images/common/project_41.png'
import img19 from '../images/common/project_42.png'
import img20 from '../images/common/project_43.png'
import img21 from '../images/common/project_44.png'
import img22 from '../images/common/project_74.jpg'
import img23 from '../images/common/project_75.jpg'
import img24 from '../images/common/project_76.jpg'
import img25 from '../images/common/project_77.jpg'
import img26 from '../images/common/project_78.jpg'
import img27 from '../images/common/project_79.jpg'
import img28 from '../images/common/project_80.jpg'
import img29 from '../images/common/project_81.jpg'
import img30 from '../images/common/project_82.jpg'
import img44 from '../images/team/1.jpg'
import img45 from '../images/team/2.jpg'
import img46 from '../images/team/3.jpg'
import img47 from '../images/team/4.jpg'
import img48 from '../images/team/5.jpg'
import img49 from '../images/team/6.jpg'
import avt1 from '../images/common/project_24.png'
import avt2 from '../images/common/project_25.png'

const dataProject = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img3,
    title: 'Zombie plant 2',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price_1: '100K',
    price_2: '23M',
    price_3: '$0',
  },
  {
    id: 8,
    img: img4,
    title: 'Zombie plant 2',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price_1: '100K',
    price_2: '23M',
    price_3: '$0',
  },
  {
    id: 9,
    img: img5,
    title: 'Iron Blade',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price_1: '100K',
    price_2: '23M',
    price_3: '$0',
  },
  {
    id: 10,
    img: img2,
    title: 'Zombie plant 2',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price_1: '100K',
    price_2: '23M',
    price_3: '$0',
  },
  {
    id: 11,
    img: img7,
    title: 'Submit KYC',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
    number: '01',
  },
  {
    id: 12,
    img: img8,
    title: 'Verify Wallet',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
    number: '02',
  },
  {
    id: 13,
    img: img9,
    title: 'Start Staking',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
    number: '03',
  },

  {
    id: 14,
    img: img10,
    title: 'Garena Free Fire MAX',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price: '332497.30 BUSD',
    price_1: '2889.18 BUSD',
    price_2: '23M',
  },

  {
    id: 15,
    img: img11,
    title: 'Oddmar',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price: '332497.30 BUSD',
    price_1: '2889.18 BUSD',
    price_2: '23M',
  },
  {
    id: 16,
    img: img10,
    title: 'Garena Free Fire MAX',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price: '332497.30 BUSD',
    price_1: '2889.18 BUSD',
    price_2: '23M',
  },

  {
    id: 17,
    img: img11,
    title: 'Oddmar',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price: '332497.30 BUSD',
    price_1: '2889.18 BUSD',
    price_2: '23M',
  },
  {
    id: 18,
    img: img12,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt1,
  },
  {
    id: 19,
    img: img13,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 20,
    img: img14,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt1,
  },
  {
    id: 21,
    img: img15,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 22,
    img: img16,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 23,
    img: img17,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 24,
    img: img18,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 25,
    img: img19,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 26,
    img: img20,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },
  {
    id: 27,
    img: img21,
    title: 'Modern Combat Versus',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    avt: avt2,
  },

  {
    id: 28,
    img: img22,
    title: 'Roblox',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 29,
    img: img23,
    title: 'Lost Lands 2',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 30,
    img: img24,
    title: 'Darkness and Flame',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 31,
    img: img25,
    title: 'DC Heroes & Villains',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 32,
    img: img26,
    title: 'New York Mysteries',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 33,
    img: img27,
    title: 'Sky Garden',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 34,
    img: img28,
    title: 'Scary Horror Game',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 35,
    img: img29,
    title: 'Knightcore Kingdom',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 36,
    img: img30,
    title: 'Infinity Battle Party',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },

  {
    id: 37,
    img: img30,
    title: 'Infinity Battle Party',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 38,
    img: img30,
    title: 'Infinity Battle Party',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },
  {
    id: 39,
    img: img30,
    title: 'Infinity Battle Party',
    price_1: '0.01',
    price_2: 'TBA',
    price_3: 'Public',
  },

  {
    id: 40,
    img: img2,
    title: 'Zombie plant 2',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius risus sed pellentesque',
    price_1: '100K',
    price_2: '23M',
    price_3: '$0',
  },
  {
    id: 41,
    img: img7,
    title: 'Proven track record',
    desc: 'Our methodology is taught in world-class schools in more than 125 countries and has been verified by numerous independent researchers, associations and universities.',
    number: '01',
  },
  {
    id: 42,
    img: img8,
    title: 'Applied technology',
    desc: 'Prepare students for careers in Game Design, Computer Science, Music Production, Filmmaking, Creative Design, Digital Storytelling, and Entrepreneurship.',
    number: '02',
  },
  {
    id: 43,
    img: img9,
    title: 'Broad learning experiences',
    desc: 'Our focus on project-based learning gives students experience with practical applications and real-world problem solving.',
    number: '03',
  },

  {
    id: 44,
    img: img44,
  },
  {
    id: 45,
    img: img45,
  },
  {
    id: 46,
    img: img46,
  },
  {
    id: 47,
    img: img47,
  },
  {
    id: 48,
    img: img48,
  },
  {
    id: 49,
    img: img49,
  },
]

export default dataProject
