import img1 from '../images/partner/01.png'
import img2 from '../images/partner/02.png'
import img3 from '../images/partner/03.png'
import img4 from '../images/partner/04.png'
import img5 from '../images/partner/05.png'
import img6 from '../images/partner/06.png'
import img7 from '../images/partner/07.png'
import img8 from '../images/partner/08.png'
import img9 from '../images/partner/09.png'
import img10 from '../images/partner/10.png'
import img11 from '../images/partner/11.png'
import img12 from '../images/partner/12.png'
import img13 from '../images/partner/13.png'
import img14 from '../images/partner/14.png'
import img15 from '../images/partner/15.png'
import img16 from '../images/partner/16.png'

const dataPartner = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },
  {
    id: 10,
    img: img10,
  },
  {
    id: 11,
    img: img11,
  },
  {
    id: 12,
    img: img12,
  },
  {
    id: 13,
    img: img13,
  },
  {
    id: 14,
    img: img14,
  },
  {
    id: 15,
    img: img15,
  },
  {
    id: 16,
    img: img16,
  },
]

export default dataPartner
