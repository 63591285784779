import HomeOne from './HomeOne'
import HomeThree from './HomeThree'
import HomeTwo from './HomeTwo'
import Project1 from './Project1'
import Project2 from './Project2'
import Project3 from './Project3'
import Project4 from './Project4'
import Project5 from './Project5'
import ProjectList from './ProjectList'
import ProjectDetails from './ProjectDetails'
import SubmitProject from './SubmitProject'
import Token from './Token'
import ConnectWallet from './ConnectWallet'
import TeamDetails from './TeamDetails'
import SubmitIGO from './SubmitIGO'
import Trails from './Trails'

import Login from './Login'
import Register from './Register'
import ForgetPass from './ForgetPass'
import Roadmap from './Roadmap'
import BlogGrid from './BlogGrid'
import BlogList from './BlogList'
import BlogDetails from './BlogDetails'
import Contact from './Contact'
import PageFaqs from './PageFaqs'
import Registration from './Registration'

const onClick = () => {
  window.open('https://wa.me/5581983737777', '_blank')
}

const routes = [
  { path: '/', component: <HomeOne linkRegister={onClick} /> },
  { path: '/trails', component: <Trails linkRegister={onClick} /> },
  { path: '/home_v2', component: <HomeTwo linkRegister={onClick} /> },
  { path: '/home_v3', component: <HomeThree linkRegister={onClick} /> },
  { path: '/project_v1', component: <Project1 linkRegister={onClick} /> },
  { path: '/project_v2', component: <Project2 linkRegister={onClick} /> },
  { path: '/project_v3', component: <Project3 linkRegister={onClick} /> },
  { path: '/project_v4', component: <Project4 linkRegister={onClick} /> },
  { path: '/project_v5', component: <Project5 linkRegister={onClick} /> },
  { path: '/project_list', component: <ProjectList linkRegister={onClick} /> },
  {
    path: '/project_details',
    component: <ProjectDetails linkRegister={onClick} />,
  },
  {
    path: '/project_submit',
    component: <SubmitProject linkRegister={onClick} />,
  },
  { path: '/token', component: <Token linkRegister={onClick} /> },
  { path: '/connect', component: <ConnectWallet linkRegister={onClick} /> },
  { path: '/team_details', component: <TeamDetails linkRegister={onClick} /> },
  { path: '/submit_igo', component: <SubmitIGO linkRegister={onClick} /> },
  { path: '/faqs', component: <PageFaqs linkRegister={onClick} /> },
  { path: '/login', component: <Login linkRegister={onClick} /> },
  { path: '/register', component: <Register linkRegister={onClick} /> },
  { path: '/forgetPass', component: <ForgetPass linkRegister={onClick} /> },
  { path: '/roadmap', component: <Roadmap linkRegister={onClick} /> },
  { path: '/blog_grid', component: <BlogGrid linkRegister={onClick} /> },
  { path: '/blog_list', component: <BlogList linkRegister={onClick} /> },
  { path: '/blog_details', component: <BlogDetails linkRegister={onClick} /> },
  { path: '/contact', component: <Contact linkRegister={onClick} /> },
  { path: '/registration', component: <Registration linkRegister={onClick} /> },
]

export default routes
