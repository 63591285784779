import React from 'react'

import Banner from '../components/banner/banner_v2'
import dataBanner from '../assets/fake-data/data-banner'
import Featured2 from '../components/featured/Featured2'
import dataFeatured from '../assets/fake-data/data-feature'
import dataProject from '../assets/fake-data/data-project'

import Faqs from '../components/faqs'
import dataFaq from '../assets/fake-data/data-faqs'
import Team from '../components/team'
import dataTeam from '../assets/fake-data/data-team'
import CTA2 from '../components/cta/CTA2'
import Token from '../components/token'
import VideoPlayer2 from '../components/video-player/VideoPlayer2'
import Project from '../components/project/project_v1/index_button'
import { useTranslation } from 'react-i18next'
import CarouselSlider from '../components/carousel'
import Potential from '../components/partner/potential'
import InMedia from '../components/partner/inMedia'
import Connections from '../components/partner/connections'
import dataPotentials from '../assets/fake-data/data-potentials'
import dataMedia from '../assets/fake-data/data-media'
import dataConnections from '../assets/fake-data/data-connections'

function Registration(props) {
  const { t } = useTranslation()

  return (
    <div className='header-fixed main home2 counter-scroll'>
      <Banner data={dataBanner} i18n={t} />

      <Token i18n={t} />

      <VideoPlayer2
        url='https://youtu.be/9lJC7zKe5e4'
        title='Descubra o caminho para o futuro em Caruaru - PE! Agende agora sua visita e embarque na Expedição Lvl1.'
        subtitle='Um tour exclusivo guiado pelo líder da nossa unidade.'
        i18n={t}
      />

      <Project
        title='Edufuturo School'
        data={dataProject}
        button_url={props.linkregister}
        sliceNumber1={0}
        sliceNumber2={6}
        i18n={t}
        desc='Discover the revolutionary Edufuturo School: a complete student-centered learning journey with bilingual classes, emotional skills development, exploration of artificial intelligence and other personalized learning. Our school authentically reproduces the world, with advanced, safe and comfortable infrastructure.'
        textButton='I Want To Know More'
      />

      <CarouselSlider data={dataProject} sliceNumber1={0} sliceNumber2={6} />

      <Featured2
        data={dataFeatured}
        sliceNumber1={4}
        sliceNumber2={10}
        i18n={t}
      />

      <CarouselSlider data={dataProject} sliceNumber1={43} sliceNumber2={49} />

      <div className='bg_body'>
        <div className='bg_h2'>
          <img
            src={require('../assets/images/backgroup/bg_home2.png')}
            alt=''
          />
        </div>

        {/* {<Project5 data={dataProject} />}

        {<Token2 data={dataToken} />}

        {<Tier data={dataTier} />}

        {<Technology data={dataPartner} />}

        {<Roadmap data={dataRoadmap} />}

        {<Project6 data={dataProject} />} */}

        {<Team data={dataTeam} i18n={t} />}
      </div>

      <Featured2
        title='Pra quem é a Edufuturo School?'
        data={dataFeatured}
        sliceNumber1={10}
        sliceNumber2={13}
        i18n={t}
      />

      <VideoPlayer2
        url='https://www.youtube.com/watch?v=0rdcH0xRF0c'
        title='Prepare seus filhos para liderar o futuro!'
        subtitle='Criamos um ambiente de aprendizado criativo, inovador e estimulante, onde o aluno se desenvolve de maneira integral.  '
        i18n={t}
      />

      {<CTA2 title='Seja um dos primeiros a matricular o seu filho!' />}

      <Potential data={dataPotentials} />

      <InMedia data={dataMedia} />

      <Connections data={dataConnections} />

      {<Faqs data={dataFaq} />}
    </div>
  )
}

export default Registration
