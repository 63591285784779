const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  // {
  //   id: 2,
  //   name: 'About',
  //   links: 'about',
  // },
  {
    id: 3,
    name: 'Trails',
    links: 'trails',
  },
  {
    id: 4,
    name: 'Contact',
    links: 'contact',
  },
  //{
  //id: 5,
  // name: 'Make a register!',
  // links: 'registration',
  //},
]

export default menus
