const br = {
  translation: {
    Login: 'Entrar',
    Trails: 'Trilhas',
    Contact: 'Contato',
    About: 'Sobre',
    Home: 'Início',
    Address: 'Endereço',
    Phone: 'Telefone',
    Support: 'Suporte',
    Subject: 'Assunto',
    Message: 'Mensagem',
    'Access platform': 'Acessar plataforma',
    "How much is your child's future worth?":
      'Quanto Vale o Futuro Do Seu Filho?',
    'Make a register!': 'Faça sua matrícula!',
    'Full name': 'Nome completo',
    'Email address': 'Email',
    'Contact us': 'Contato',
    'Business + Entrepreneurship': 'Negócios + Empreendedorismo',
    'Quick Link': 'Links Rápidos',
    'Us In The Media': 'Edufuturo na mídia',
    'Our Connections': 'Nossas conexões',
    'Unlock Your Potential': 'Desbloqueie seu potencial',
    'Get to know Edufuturo': 'Conheça a Edufuturo',
    'The End Of Complexity': 'O Fim Da Complexidade',
    'How To Prepare For The Unexpected?': 'Como se preparar para o inesperado?',
    'The Courses': 'Os Cursos',
    'The Classes': 'As Aulas',
    'The Students': 'Os Estudantes',
    'The Project': 'O Projeto',
    'The Follow-up': 'O Acompanhamento',
    'Creative Design': 'Design Criativo',
    'Music Production': 'Produção Musical',
    'Your name': 'Nome completo',
    'Your email': 'Seu email',
    'Your phone': 'Seu telefone',
    'Enter the subject': 'Assunto',
    'Get in touch': 'Entre em contato',
    'I Want To Know More': 'Quero saber mais',
    'Any questions left? Contact us!':
      'Ficou alguma dúvida? Entre em contato conosco!',
    'Type your message': 'Digite a sua mensagem',
    'Robotics + Engineering': 'Robótica + Engenharia',
    'Computer Science + Artificial Intelligence':
      'Ciência Da Computação + Inteligência Artificial',
    'Few things are more rewarding than building something for yourself. Throughout the course, students will learn how to build and program robots using Arduino.':
      'Poucas coisas são mais gratificantes do que construir algo para si mesmo. ao longo do curso, os alunos aprenderão como construir e programar robôs usando o Arduino.',
    'Designed by Edufuturo': 'Desenvolvido pela Edufuturo',
    'Learning by playing on a platform with its own currency and engaging gamification process':
      'Aprender brincando em uma plataforma com moeda própria e processo de gamificação envolvente',
    'Awakening A New Consciousness In The World':
      'Despertando Uma Nova Consciência No Mundo',
    'Applied technology': 'Tecnologia aplicada',
    'Proven track record': 'Histórico comprovado',
    'Broad learning experiences': 'Amplas experiências de aprendizagem',
    'American Teaching Methodology': 'Metodologia De Ensino Americana',
    'Learn while playing on a platform with its own currency and an engaging gamification process':
      'Aprender brincando em uma plataforma com moeda própria e processo de gamificação envolvente',
    'Methodology Used by Educational In Numbers':
      'Metodologia Utilizada Pelo Edufuturo Em Números',
    'Unleash Your Potential And Master Your Skills':
      'Libere Seu Potencial E Domine Suas Habilidades',
    'Methodology Used by Edufuturo In Numbers':
      'Metodologia Utilizada pelo Edufuturo Em Números',
    'Our focus on project-based learning gives students experience with practical applications and real-world problem solving.':
      'Nosso foco no aprendizado baseado em projetos oferece aos alunos experiência com aplicações práticas e resolução de problemas do mundo real.',
    'Our courses are made up of annual knowledge tracks, that is, the student will study for a year taking several courses according to the chosen track. These trails are structured according to age group, being divided into two: - EDUfuturo Start Kids Trail for children between 8 and 11 years old; - EDUfuturo Start Teen Trail for teenagers aged 11 to 18.':
      'Nossos cursos são compostos por trilhas de conhecimento anuais, ou seja, o aluno estudará durante um ano realizando diversos cursos de acordo com a trilha escolhida. Estes percursos estão estruturados por faixa etária, sendo divididos em dois: - Trilho EDUfuturo Start Kids para crianças entre os 8 e os 11 anos; - EDUfuturo Start Teen Trail para adolescentes de 11 a 18 anos.',
    "Today's young people must be prepared to thrive in an ever-evolving technology landscape. Developing valuable life skills, revolutionary learning experiences that are sought after in today's job market around the world, align BUSSINESS PRO, REAL SKILLS, HARD SKILLS and in fact, should encompass the concept of CORE SKILLS, neither Hard nor Soft, a mix everything.":
      'Os jovens de hoje devem estar preparados para prosperar em um cenário tecnológico em constante evolução. Desenvolver habilidades valiosas para a vida, experiências de aprendizagem revolucionárias que são procuradas no mercado de trabalho atual do mundo todo, alinham BUSSINESS PRO, REAL SKILLS, HARD SKILLS e na verdade, devem englobar o conceito de CORE SKILLS, nem Hard nem Soft, um misture tudo.',
    'Classes will take place in the morning, afternoon and evening, with one class per week lasting 1:30 hours, and classes of a maximum of 14 students. All classes are accompanied by a bilingual Teacher of the Future who will lead the classes introducing English in a didactic way with real and practical project applications.':
      'As aulas irão acontecer nos turnos da manhã, tarde e noite, sendo uma aula por semana com 1:30h de duração, e turmas de no máximo 14 alunos. Todas as aulas são acompanhadas por um Professor do Futuro bilíngue que conduzirá as aulas introduzindo o inglês de forma didática com aplicações de projetos reais e práticos.',
    'Students will have access to their achievements, scores, badges, avatar and all teaching material for the course that will be made available through our fully gamified EAD online content platform where the student will access video classes, support material, quizzes, questionnaires and all course materials.':
      'Os alunos terão acesso a suas conquistas, pontuações, emblemas, avatar e todo material didático do curso que será disponibilizado através da nossa plataforma EAD de conteúdo online totalmente gamificada onde o aluno vai acessar as videoaulas, material de apoio, quiz, questionários e todos os materiais do curso.',
    'At the end of each course, students will have to create a project and present it to the class. The project will also be documented and submitted on the platform following the methodology standards.':
      'Ao final de cada curso, os alunos terão que criar um projeto e apresentá-lo à turma. O projeto também será documentado e enviado à plataforma de acordo com as normas metodológicas.',
    "Parents and guardians will be able to monitor the student's development through reports that will be sent explaining their progress in detail and also through our online platforms.":
      'Os pais e responsáveis ​​poderão acompanhar todo o desenvolvimento do aluno através de relatórios que serão enviados explicando o progresso deles em detalhes e também através de nossas plataformas online.',
    'A school that surprises at every opportunity':
      'Uma escola que surpreende a cada oportunidade',
    'Schools must focus on being “complete schools”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers truly ready for lifes challenges.':
      'Escolas devem focar em serem “complete School”, em transformação de verdade, elas devem ser escolas que surpreendam a cada oportunidade. Crianças e Adolescentes verdadeiramente prontas para os desafios da vida.',
    'Our methodology is taught in world-class schools in more than 125 countries and has been verified by numerous independent researchers, associations and universities.':
      'Nossa metodologia é ensinada em escolas de nível mundial em mais de 125 países e foi verificada por vários pesquisadores, associações e universidades independentes.',
    'Prepare students for careers in Game Design, Computer Science, Music Production, Filmmaking, Creative Design, Digital Storytelling, and Entrepreneurship.':
      'Prepare os alunos para carreiras em Game Design, Computer Science, Music Production, Filmmaking, Creative Design, Digital Storytelling, and Entrepreneurship.',
    'Schools must focus on being a “Complete School”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers really ready for lifes challenges.':
      'As escolas devem focar em ser “Escola completa”, em transformação de verdade, elas devem ser escolas que surpreendam a cada oportunidade. Crianças e Adolescentes realmente prontas para os desafios da vida.',
    'Enroll your child now and be part of this transformation for the future.':
      'Matricule seu filho agora mesmo e faça parte dessa transformação para o futuro.',
    'The essence of the School of Education of the Future is to produce human solutions through technology. We open a connection with a universe of possibilities through gamification, artificial intelligence, among others. This is our spirit, our way of inspiring new generations.':
      'A essência da Escola de Educação do Futuro é produzir soluções humanas por meio da tecnologia. Abrimos uma conexão com um universo de possibilidades através da gamificação, inteligência artificial, entre outros. Este é o nosso espírito, a nossa forma de inspirar as novas gerações.',
    'Entrepreneurs are made, not born. This course was developed and designed in coordination with business school educational developers, industry consultants, and students.':
      'Os empreendedores são feitos, não nascem. Este curso foi desenvolvido e projetado em coordenação com desenvolvedores educacionais de escolas de negócios, consultores da indústria e alunos.',
    'Students will begin by learning and sharing key thematic styles used by social content creators across multiple platforms. Students will learn the methods used by creators to publish different types of video and photo content on the social media platforms YouTube, TikTok, and Instagram.':
      'Os alunos começarão aprendendo e compartilhando os principais estilos temáticos utilizados por criadores de conteúdo social em várias plataformas. Os alunos aprenderão os métodos usados ​​pelos criadores para publicar diferentes tipos de conteúdo de vídeo e foto nas redes sociais YouTube, TikTok e Instagram.',
    'Students will create their own artificial intelligence, such as chatbots, natural language processing, and recommendation systems. Use AI tools like machine learning to create intelligent programs like chatbots from scratch.':
      'Os alunos criarão sua inteligência artificial própria, como chatbots, processamento de linguagem natural e sistemas de recomendação. Use ferramentas de IA, como aprendizado de máquina, para criar programas inteligentes como chatbots do zero.',
    'This course offers a great introduction to game design principles, including interactive storytelling, character creation, and publishing. Our hands-on, design-thinking approach encourages students to unleash their inner designer as well as work collaboratively with other students.':
      'Este curso oferece uma ótima introdução aos princípios de design de jogos, incluindo narrativa interativa, criação de personagens e publicação. Nossa abordagem prática e de pensamento de design incentiva os alunos a liberar seu designer interno, bem como a trabalhar em colaboração com outros alunos.',
    'Discover how animators at Pixar, Cartoon Network, and Disney produce cartoons and films by understanding the principles of life drawing. Develop the art of storytelling by thinking sequentially to create storyboards.':
      'Descubra como os animadores da Pixar, Cartoon Network e Disney produzem desenhos animados e filmes compreendendo os princípios do desenho de vida. Desenvolva a arte de contar histórias pensando sequencialmente para criar storyboards.',
    'Students learn the science of sound and synthesis and how to loop musical ideas in the Ableton Live session view. Students will arrange and produce an existing popular song and learn the fundamentals of music production.':
      'Os alunos aprendem a ciência do som e da síntese e como criar um loop de ideias musicais na visualização da sessão do Ableton Live. Os alunos terão seus próprios arranjos e produção de uma canção popular existente e aprenderão os fundamentos da produção musical.',
    "Our mentor educators are experts in their fields, committed to inspiring and guiding students on their journey of discovery and growth. A highly qualified team to ensure quality education, focused on developing essential skills for the global job market. Here, we also understand the importance of the partnership between school and family, which is why we offer an efficient and transparent communication system. This way, you will always be involved in your child's educational and behavioral process, closely monitoring their development and progress.":
      'Nossos educadores mentores são especialistas em suas áreas, comprometidos em inspirar e orientar os estudantes em sua jornada de descoberta e crescimento. Um super time altamente qualificado para garantir um ensino de qualidade, focado no desenvolvimento de habilidades essenciais para o mercado de trabalho global. Aqui, nós também entendemos a importância da parceria entre a escola e a família, por isso, oferecemos um sistema de comunicação eficiente e transparente. Assim, você estará sempre envolvido no processo educacional e comportamental de seu filho, acompanhando de perto seu desenvolvimento e progresso.',
    'The future is uncertain, but your child can be prepared for any scenario with Edufuturo.':
      'O futuro é incerto, mas seu filho pode estar preparado para qualquer cenário com a Edufuturo.',
    'The future is uncertain': 'O futuro é incerto',
    'But your child can be prepared for any scenario with Edufuturo':
      'Mas seu filho pode estar preparado para qualquer cenário com o Edufuturo.',
    'Take Your Skills To The Next Level!':
      'Leve suas habilidades para o próximo nível!',
    'The future is born here': 'O futuro nasce aqui',
    'At Edufuturo, we create a future where education transforms lives and creates human solutions through technology. We are more than a school; we are an innovation hub that connects children and teenagers to a universe of possibilities.':'Na Edufuturo, criamos um futuro onde a educação transforma vidas e cria soluções humanas por meio da tecnologia. Somos mais do que uma escola; somos um hub de inovação que conecta crianças e adolescentes a um universo de possibilidades.',
    'Our Future Rooms are innovative spaces that integrate technology, design and interactivity. With features such as digital screens, artificial intelligence, robotics and gamification, we create an immersive environment that inspires creativity and collaborative learning. Each space is designed to foster curiosity, engagement and innovation.':'Nossas Salas do Futuro são espaços inovadores que integram tecnologia, design e interatividade. Com recursos como telas digitais, inteligência artificial, robótica e gamificação, criamos um ambiente imersivo que inspira criatividade e aprendizado colaborativo. Cada espaço é projetado para promover curiosidade, engajamento e inovação.',
    'Transformative Environment':'Ambiente Transformador',
    'We adopt a Project-Based Learning (PBL) approach, where students, called Changemakers, solve real-world problems while learning. They work on hands-on projects that explore areas such as Science and Technology, Entrepreneurship, Art and Design, Music, Media, Film and Games. Teaching is bilingual or entirely in English, and our students have access to international experiences, global certifications and exchanges, connecting them to the global market and world cultures, as well as exclusive access to a gamified platform.':'Adotamos uma abordagem baseada em projetos (PBL - Project-Based Learning), onde os alunos, chamados de Transformeiros, resolvem problemas reais enquanto aprendem. Eles trabalham em projetos práticos que exploram áreas como Ciência e Tecnologia, Empreendedorismo, Arte e Design, Música, Mídia, Cinema e Games. O ensino é bilíngue ou totalmente em inglês, e nossos alunos têm acesso a experiências internacionais, certificações globais e intercâmbios, conectando-os ao mercado global e às culturas do mundo, além de acesso exclusivo a uma plataforma gamificada.',
    'Active and Immersive Methodology':'Metodologia Ativa e Imersiva',
    'Each stage of learning is designed to develop technical skills (Hard Skills), emotional skills (Soft Skills) and, most importantly, Core Skills – a fusion of essential competencies for life and the job market. We celebrate achievements through special events, where students present their projects and reaffirm their commitment to innovation, continuous learning and positive impact on society.':'Cada etapa do aprendizado é projetada para desenvolver habilidades técnicas (Hard Skills), emocionais (Soft Skills) e, principalmente, Core Skills – uma fusão de competências essenciais para a vida e o mercado de trabalho. Celebramos conquistas por meio de eventos especiais, onde alunos apresentam seus projetos e reafirmam seu compromisso com a inovação, o aprendizado contínuo e o impacto positivo na sociedade.',
    'Purpose Aligned with the Future':'Propósito Alinhado com o Futuro',
    'In practice, Edufuturo is more than teaching – it is inspiring.':'Na prática, a Edufuturo é mais do que ensinar – é inspirar.',
    'We inspire young people to think, create, collaborate and lead, offering an education that transforms them into protagonists in a constantly evolving world. Every class, every project, every experience is a step towards building a bright future.':'Inspiramos jovens a pensar, criar, colaborar e liderar, oferecendo uma educação que os transforma em protagonistas do mundo em constante evolução. Cada aula, cada projeto, cada experiência é um passo na construção de um futuro brilhante.',
    'How Edufuturo Works in Practice':'Como Funciona a Edufuturo na Prática',
    'Edufuturo combines innovation and methodology to offer a unique educational experience, designed to prepare children and adolescents for the challenges of the future. Our operation is based on three main pillars: environment, methodology and purpose.':'A Edufuturo combina inovação e metodologia para oferecer uma experiência educacional única, projetada para preparar crianças e adolescentes para os desafios do futuro. Nosso funcionamento é baseado em três pilares principais: ambiente, metodologia e propósito.',
  },
}
export default br
