import img1 from '../images/media/01.png'
import img2 from '../images/media/02.png'
import img3 from '../images/media/03.png'
import img4 from '../images/media/04.png'
import img5 from '../images/media/05.png'
import img6 from '../images/media/06.png'

const dataMedia = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
]

export default dataMedia
