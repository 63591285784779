import React from 'react'

import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import '@vidstack/react/player/styles/base.css'
import '@vidstack/react/player/styles/plyr/theme.css'

import { MediaPlayer, MediaProvider } from '@vidstack/react'
import {
  PlyrLayout,
  plyrLayoutIcons,
} from '@vidstack/react/player/layouts/plyr'

function VideoPlayer2(props) {
  const [dataTitle] = useState({
    title: props.title,
    subtitle: props.subtitle,
    url: props.url,
  })
  const { t } = useTranslation()
  return (
    <section className='tf-section tf_CTA'>
      <div className='container relative'>
        <div className='overlay'></div>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-10'>
            <div
              className='tf-title'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <div data-aos='fade-up' data-aos-duration='800'>
                <h4 className='title'>{t(dataTitle.title)}</h4>
                <p className='sub'>{t(dataTitle.subtitle)}</p>
              </div>
              <MediaPlayer
                title='Uma escola que surpreende a cada oportunidade.'
                src={dataTitle.url}
              >
                <MediaProvider />
                <PlyrLayout icons={plyrLayoutIcons} />
              </MediaPlayer>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
      </div>
    </section>
  )
}

export default VideoPlayer2
