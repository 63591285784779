import img1 from '../images/common/project_17.png'
import img2 from '../images/common/project_18.png'
import img3 from '../images/common/project_19.png'
import img4 from '../images/common/project_20.png'

const dataTier = [
  {
    id: 1,
    img: img1,
    title: 'Basic',
    unit1: '$9,000',
    unit2: 'Lottery(20%)',
    unit3: 'Whitelist task',
    unit4: '03',
  },
  {
    id: 2,
    img: img2,
    title: 'Medium',
    unit1: '$20,000',
    unit2: 'Lottery(50%)',
    unit3: 'Whitelist task',
    unit4: '07',
  },
  {
    id: 3,
    img: img3,
    title: 'Advanced',
    unit1: '$30,000',
    unit2: 'Guaranteed',
    unit3: 'No',
    unit4: '14',
  },
  {
    id: 4,
    img: img4,
    title: 'Ultimate',
    unit1: '$40,000',
    unit2: 'Guaranteed',
    unit3: 'No',
    unit4: '24',
  },
]

export default dataTier
