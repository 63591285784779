const dataFaq = [
  {
    id: 1,
    title: 'É uma escola tradicional ou período complementar?',
    content:
      'Funcionamos no sistema de contraturno. Oferecemos um ambiente educativo e enriquecedor para os alunos durante o período complementar à sua carga horária escolar regular. Aqui, eles têm a oportunidade de continuar aprendendo por meio de atividades extracurriculares, desenvolver habilidades e explorar seus interesses de maneira ampla, complementando a formação acadêmica e promovendo um crescimento integral.',
  },
  {
    id: 2,
    title: 'É uma escola tradicional ou período complementar?',
    content: 'Crianças e adolescentes entre 7 e 17 anos.',
  },
  {
    id: 3,
    title: 'Existem outras unidades fora de Caruaru?',
    content:
      'Atualmente a Edufuturo School, empresa de educação privada do Grupo Edufuturo, atua exclusivamente em Caruaru.',
  },
  {
    id: 4,
    title: '4. Como funciona o processo de matrícula?',
    content:
      'É só preencher esse formulário que a nossa equipe irá entrar em contato com você. Mas atenção, as vagas são limitadas.',
  },
  {
    id: 5,
    title: 'Existe algum certificado?',
    content:
      'Ao final de cada jornada de aprendizagem, nossos alunos recebem um certificado de conclusão, além de medalhas e outras premiações que fazem parte da gamificação.',
  },
]

export default dataFaq
