import img1 from '../images/common/icon_9.png'
import img2 from '../images/common/icon_10.png'
import img3 from '../images/common/icon_11.png'

const dataStep = [
  {
    id: 1,
    img: img1,
    title: 'Submit KYC',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
  {
    id: 2,
    img: img2,
    title: 'Verify Wallet',
    text: 'Etiam nisi libero, sodales sit amet justo ac, suscipit maximus metus. Semper nec interdum nec, faucibus id dui sit amet congue',
  },
  {
    id: 3,
    img: img3,
    title: 'Start Staking',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
  {
    id: 4,
    img: img3,
    title: 'Start Staking',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.',
  },
]

export default dataStep
