import img1 from '../images/common/team_1.png'
import img2 from '../images/common/team_2.png'
import img3 from '../images/common/team_3.png'
import img4 from '../images/common/team_4.png'
import img5 from '../images/common/team_5.png'
import img6 from '../images/common/team_6.png'
import img7 from '../images/common/team_7.png'
import img8 from '../images/common/team_8.png'

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: 'Tony Medeiros',
    unit: 'Diretor Executivo',
  },
  {
    id: 2,
    img: img2,
    name: 'Carlos César',
    unit: 'Diretor de Transformação Digital',
  },
  {
    id: 3,
    img: img3,
    name: 'Kaic Rannys',
    unit: 'Diretor de Conexões Humanas',
  },
  {
    id: 4,
    img: img4,
    name: 'Henrique Foresti',
    unit: 'Diretor de Conhecimento',
  },
  {
    id: 5,
    img: img5,
    name: 'Leopoldo Nóbrega',
    unit: 'Diretor de Arte',
  },
  {
    id: 6,
    img: img6,
    name: 'Sarah Holanda',
    unit: 'Diretora de Comunicação',
  },
  {
    id: 7,
    img: img7,
    name: 'Edy Rodrigues',
    unit: 'Líder Edufuturo School',
  },
  {
    id: 8,
    img: img8,
    name: 'Jeferson Gonzaga',
    unit: 'Jurídico',
  },
  {
    id: 9,
    img: img8,
    name: 'Edson Pinto',
    unit: 'Diretor das Emoções',
  },
]

export default dataTeam
