import img1 from '../images/potentials/01.png'
import img2 from '../images/potentials/02.png'
import img3 from '../images/potentials/03.png'
import img4 from '../images/potentials/04.png'
import img5 from '../images/potentials/05.png'
import img6 from '../images/potentials/06.png'
import img7 from '../images/potentials/07.png'
import img8 from '../images/potentials/08.png'
import img9 from '../images/potentials/09.png'
import img10 from '../images/potentials/10.png'
import img11 from '../images/potentials/11.png'
import img12 from '../images/potentials/12.png'
import img13 from '../images/potentials/13.png'
import img14 from '../images/potentials/14.png'
import img15 from '../images/potentials/15.png'
import img16 from '../images/potentials/16.png'
import img17 from '../images/potentials/17.png'
import img18 from '../images/potentials/18.png'
import img19 from '../images/potentials/19.svg'
import img20 from '../images/potentials/20.png'
import img21 from '../images/potentials/21.png'
import img22 from '../images/potentials/22.png'
import img23 from '../images/potentials/23.png'

const dataPotentials = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 12,
    img: img12,
  },
  {
    id: 14,
    img: img14,
  },
  {
    id: 15,
    img: img15,
  },
  {
    id: 10,
    img: img10,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },

  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },

  {
    id: 11,
    img: img11,
  },

  {
    id: 13,
    img: img13,
  },
  {
    id: 16,
    img: img16,
  },
  {
    id: 17,
    img: img17,
  },
  {
    id: 18,
    img: img18,
  },
  {
    id: 19,
    img: img19,
  },
  {
    id: 23,
    img: img23,
  },

  {
    id: 21,
    img: img21,
  },
  {
    id: 22,
    img: img22,
  },
  {
    id: 20,
    img: img20,
  },
]

export default dataPotentials
